<template>
  <div>
    <a-row :gutter="20" style="padding: 0 10px">
      <a-col :span="24">
        <a-form-item>
          <label
            :class="this.form.getFieldValue('client_email') ? 'filled' : ''"
            >Email</label
          >
          <a-input
            class="travel-input"
            :class="contract.status == 'new' ? '' : 'readonly'"
            placeholder="Email"
            v-decorator="[
              'client_email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item>
          <label :class="this.form.getFieldValue('client_name') ? 'filled' : ''"
            >Nome completo</label
          >
          <a-input
            class="travel-input"
            :class="contract.status == 'new' ? '' : 'readonly'"
            placeholder="Nome completo"
            v-decorator="[
              'client_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="10">
        <a-form-item>
          <label
            :class="this.form.getFieldValue('client_birthday') ? 'filled' : ''"
            >Nascimento</label
          >
          <a-date-picker
            class="travel-input"
            placeholder="Nascimento"
            format="DD/MM/YYYY"
            v-mask="'##/##/####'"
            :showToday="false"
            :disabled-date="adultHoodDate"
            @openChange="defaultDate"
            v-decorator="[
              'client_birthday',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </a-date-picker>
        </a-form-item>
      </a-col>

      <a-col v-show="contract.person_type == 'Pessoa Física'" :span="14">
        <a-form-item>
          <label :class="this.form.getFieldValue('client_CPF') ? 'filled' : ''"
            >CPF</label
          >
          <a-input
            class="travel-input"
            placeholder="CPF"
            v-mask="'###.###.###-##'"
            v-decorator="[
              'client_CPF',
              {
                rules: [
                  {
                    required:
                      contract.person_type == 'Pessoa Física' ? true : false,
                    min: 9,
                    message: 'Preencha todos números.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col v-show="contract.person_type == 'Pessoa Jurídica'" :span="14">
        <a-form-item>
          <label :class="this.form.getFieldValue('client_CNPJ') ? 'filled' : ''"
            >CNPJ</label
          >
          <a-input
            class="travel-input"
            placeholder="CNPJ"
            v-mask="'##.###.###/####-##'"
            v-decorator="[
              'client_CNPJ',
              {
                rules: [
                  {
                    required:
                      contract.person_type == 'Pessoa Jurídica' ? true : false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col v-show="contract.person_type == 'Pessoa Física'" :span="10">
        <a-form-item>
          <label :class="this.form.getFieldValue('client_RG') ? 'filled' : ''"
            >RG</label
          >
          <a-input
            class="travel-input"
            placeholder="RG"
            v-mask="['##.###.###', '#.###.###', '#.###.##', '###.###.###-##']"
            v-decorator="[
              'client_RG',
              {
                rules: [
                  {
                    required:
                      contract.person_type == 'Pessoa Física' ? true : false,
                    message: 'Obrigatório',
                  },
                  {
                    min: 8,
                    message: 'Preencha todos números',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col v-show="contract.person_type == 'Pessoa Física'" :span="8">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_doc_emission') ? 'filled' : ''
            "
            >Órgão Emissor</label
          >
          <a-input
            class="travel-input"
            type="text"
            placeholder="Órgão Emissor"
            v-decorator="[
              'client_doc_emission',
              {
                rules: [
                  {
                    required:
                      contract.person_type == 'Pessoa Física' ? true : false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col v-show="contract.person_type == 'Pessoa Física'" :span="6">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_doc_emission_state')
                ? 'filled'
                : ''
            "
            >ES</label
          >
          <a-select
            class="travel-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'client_doc_emission_state',
              {
                rules: [
                  {
                    required:
                      contract.person_type == 'Pessoa Física' ? true : false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="10">
        <a-form-item>
          <label
            :class="this.form.getFieldValue('client_phone') ? 'filled' : ''"
            >Telefone fixo</label
          >
          <a-input
            class="travel-input"
            :class="contract.status == 'new' ? '' : 'readonly'"
            placeholder="Telefone fixo"
            v-mask="'(##) ####-####'"
            v-decorator="[
              'client_phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="10">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_mobile_phone') ? 'filled' : ''
            "
            >Celular</label
          >

          <a-input
            class="travel-input"
            placeholder="Celular"
            v-mask="'(##) # ####-####'"
            v-decorator="['client_mobile_phone']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="10">
        <a-form-item>
          <label :class="this.form.getFieldValue('client_CEP') ? 'filled' : ''"
            >CEP</label
          >
          <a-input
            class="travel-input"
            placeholder="CEP"
            v-mask="'#####-###'"
            v-decorator="[
              'client_CEP',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @change="getLocation()"
          >
            <a-icon slot="prefix" type="field-svg" />

            <a-icon v-if="cepLoading" slot="suffix" type="loading" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_street') ? 'filled' : ''
            "
            >Logradouro</label
          >
          <a-input
            class="travel-input"
            placeholder="Logradouro"
            v-decorator="[
              'client_location_street',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_number') ? 'filled' : ''
            "
            >Número</label
          >
          <a-input
            class="travel-input"
            placeholder="Número"
            v-decorator="[
              'client_location_number',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>

      <a-col :span="10">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_complement')
                ? 'filled'
                : ''
            "
            >Complemento</label
          >
          <a-input
            class="travel-input"
            placeholder="Complemento"
            v-decorator="['client_location_complement']"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="9">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_neighborhood')
                ? 'filled'
                : ''
            "
            >Bairro</label
          >
          <a-input
            class="travel-input"
            placeholder="Bairro"
            v-decorator="[
              'client_location_neighborhood',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="9">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_city') ? 'filled' : ''
            "
            >Cidade</label
          >
          <a-input
            class="travel-input"
            placeholder="Cidade"
            v-decorator="[
              'client_location_city',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </a-input>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item>
          <label
            :class="
              this.form.getFieldValue('client_location_state') ? 'filled' : ''
            "
            >Estado</label
          >
          <a-select
            class="travel-input"
            show-search
            placeholder="ES"
            v-decorator="[
              'client_location_state',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of states"
              :key="index"
              :value="item.initials"
            >
              {{ item.initials.toUpperCase() }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <!-- <a-col
                v-if="contract.meta['client_consultant'] != undefined"
                class="mt-20"
                :span="12"
            >
                <a-form-item>
                    <label class="filled"
                        >Consultor responsável no infotera</label
                    >

                    <font class="f12 cprimary" style="font-weight: 500">
                        {{ contract.meta.client_consultant }}
                    </font>
                </a-form-item>
            </a-col> -->
    </a-row>
  </div>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";
import subYears from "date-fns/subYears";
import moment from "moment";

moment.locale("pt-br");

export default {
  name: "ClientForm",
  props: {
    form: Object,
    contract: Object,
    tempContract: Object,
  },
  data() {
    return {
      states: [
        { name: "Acre", initials: "AC" },
        { name: "Alagoas", initials: "AL" },
        { name: "Amapá", initials: "AP" },
        { name: "Amazonas", initials: "AM" },
        { name: "Bahia", initials: "BA" },
        { name: "Ceará", initials: "CE" },
        { name: "Distrito Federal", initials: "DF" },
        { name: "Espírito Santo", initials: "ES" },
        { name: "Goiás", initials: "GO" },
        { name: "Maranhão", initials: "MA" },
        { name: "Mato Grosso", initials: "MT" },
        { name: "Mato Grosso do Sul", initials: "MS" },
        { name: "Minas Gerais", initials: "MG" },
        { name: "Pará", initials: "PA" },
        { name: "Paraíba", initials: "PB" },
        { name: "Paraná", initials: "PR" },
        { name: "Pernambuco", initials: "PE" },
        { name: "Piauí", initials: "PI" },
        { name: "Rio de Janeiro", initials: "RJ" },
        { name: "Rio Grande do Norte", initials: "RN" },
        { name: "Rio Grande do Sul", initials: "RS" },
        { name: "Rondônia", initials: "RO" },
        { name: "Roraima", initials: "RR" },
        { name: "Santa Catarina", initials: "SC" },
        { name: "São Paulo", initials: "SP" },
        { name: "Sergipe", initials: "SE" },
        { name: "Tocantins", initials: "TO" },
      ],
      cepLoading: false,
    };
  },
  methods: {
    getLocation() {
      setTimeout(() => {
        if (this.form.getFieldValue("client_CEP")) {
          if (
            this.form.getFieldValue("client_CEP").replace("-", "").length == 8
          ) {
            this.cepLoading = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.form.getFieldValue("client_CEP") +
                  "/json/"
              )
              .then(({ data }) => {
                data;
                this.cepLoading = false;

                setTimeout(() => {
                  this.form.setFieldsValue({
                    client_location_street: data.logradouro.toUpperCase(),
                    client_location_state: data.uf,
                    client_location_city: data.localidade.toUpperCase(),
                    client_location_neighborhood: data.bairro.toUpperCase(),
                  });

                  // this.tempContract[
                  //     "client_location_street"
                  // ] = data.logradouro.toUpperCase();
                  this.tempContract["client_location_state"] = data.uf;
                  this.tempContract["client_location_city"] =
                    data.localidade.toUpperCase();
                  this.tempContract["client_location_neighborhood"] =
                    data.bairro.toUpperCase();
                }, 20);
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Houve um problema ao recuperar a localização."
                );
                this.cepLoading = false;
              });
          }
        }
      }, 110);
    },
    defaultDate() {
      setTimeout(() => {
        this.form.setFieldsValue({
          client_birthday: this.form.getFieldValue("client_birthday")
            ? this.form.getFieldValue("client_birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
  },
};
</script>
